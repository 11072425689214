import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/Seo";

import jeanine from "../assets/images/artists/jeanine/profile/jeanine-artist-profile.jpeg";
import jeanine001 from "../assets/images/artists/jeanine/work/jeanine-001.jpg";
import jeanine002 from "../assets/images/artists/jeanine/work/jeanine-002.jpg";
import jeanine003 from "../assets/images/artists/jeanine/work/jeanine-003.jpg";
import jeanine004 from "../assets/images/artists/jeanine/work/jeanine-004.jpg";
import jeanine005 from "../assets/images/artists/jeanine/work/jeanine-005.jpg";
import jeanine006 from "../assets/images/artists/jeanine/work/jeanine-006.jpg";
import jeanine007 from "../assets/images/artists/jeanine/work/jeanine-007.jpg";
import jeanine008 from "../assets/images/artists/jeanine/work/jeanine-008.jpg";
import jeanine009 from "../assets/images/artists/jeanine/work/jeanine-009.jpg";
import jeanine010 from "../assets/images/artists/jeanine/work/jeanine-010.jpg";
import jeanine011 from "../assets/images/artists/jeanine/work/jeanine-011.jpg";
import jeanine012 from "../assets/images/artists/jeanine/work/jeanine-012.jpg";
import jeanine013 from "../assets/images/artists/jeanine/work/jeanine-013.jpg";
import jeanine014 from "../assets/images/artists/jeanine/work/jeanine-014.jpg";

const Artist_Jeanine = props => (
  <Layout>
    <SEO
      title="Artist Jeanine Hauk"
      keywords={[`obsidian la`, `obsidian la tattoo`, `los angeles tattoo`]}
    />

    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Jeanine Hauk</h1>
        </header>
        <div className="image">
          <img src={jeanine} alt="jeanine" className="image left" />
          <p>
            Jeanine Hauk is a Los Angeles native tattooer. She studied Graphic
            Design at California College of the Arts in San Francisco. Jeanine
            has an innate intrigue and admiration for the craft and believed it
            was time to pursue it.She began her apprenticeship with Kayle
            Leogrande at Classic Tattoo Upland. She has been tattooing for four
            years and enjoys doing American traditional. She specializes in
            ladyheads, pinups, and everything NSFW. With a deep understanding of
            the craft, Hauk naturally fills the gap—connecting the client closer
            to their imagination.
          </p>

          <hr />
          <h4 style={{ float: `left`, marginTop: `.3em` }}>
            <a
              href="https://www.instagram.com/lady_hauk/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp; @lady_hauk
            </a>
          </h4>
          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="tiles"
        style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
      >
        <article
          style={{
            backgroundImage: `url(${jeanine001})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine002})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine003})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine004})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine005})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine006})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine007})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine008})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine009})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine010})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine011})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine012})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine013})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${jeanine014})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
      </section>
    </div>
  </Layout>
);

// export const query = graphql`
// query Artistjeanine {
//     allInstaNode (limit: 15) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `

export default Artist_Jeanine;
